import * as React from "react"

import AnimateScene from "../components/jds_animate"
import Project from "../components/jds_projects"

import Footer from "../components/jds_footer"
import Resume from "../components/jds_resume"

import Blocks from "../components/jds_blackout"

import  cArrow from "../assets/cropArrows.png"
import  logo from "../assets/Name.png"
import  portfolioTag from "../assets/TAG_PORTFOLIO.png"
import  resumeTag from "../assets/TAG_RESUME.png"
import  profilePhoto from "../assets/Justin Headshot.png"

import {Helmet} from 'react-helmet'
import { isMobile } from "react-device-detect"



// stlyes
const pageStyles = {
  position :"absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right:0,
}

const logoStyles ={
  //position: "absolute",
  marginTop:50,
  marginLeft: 50,
  width: 100
}

const cropArrowStyle = [
  {
    position :"fixed",
    top: 0,
    left: 0,
    
  },
  {
    position :"fixed",
    top: 0,
    right: 0,
    transform: `rotate(90deg)`,
  },
  {
    position :"fixed",
    bottom: 0,
    right: 0,
    transform: `rotate(180deg)`,
  },
  {
    position :"fixed",
    bottom: 0,
    left: 0,
    transform: `rotate(270deg)`,
  },

]

const profileImageStyle ={
  height:200,
  marginTop:50,
}



class NavBar extends React.Component{
  constructor(props){
    super(props);
    this.state = {dropdown: false,
                  navbar:false};
    }
    
  toggleDropdown = () => this.setState({dropdown:!this.state.dropdown});

  toggleNavbar = () => this.setState({navbar:!this.state.navbar})

  closeDropdown = () => this.setState({dropdown:false})

  render(){
    return(
      <nav className="navbar navbar-expand-lg navbar-light w-100" style={{top:50, position:"fixed"}}>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation" onClick={this.toggleNavbar} style={{marginLeft:'auto',marginRight:50}}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`navbar-collapse collapse ${this.state.navbar?`show`:``}`} id="navbarToggler">
          <ul className="navbar-nav" style={{marginLeft:'auto',marginRight:`10%`, textAlign:`right`}}>
            <li className="nav-item active">
              <a className="nav-link" href="#home" onClick={this.closeDropdown}>Home</a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#work" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.toggleDropdown}>Work</a>
              <div className={`dropdown-menu ${this.state.dropdown ? 'show' : ''}`} aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="#project1" onClick={this.toggleDropdown}>Project 1</a>
                <a className="dropdown-item" href="#project2" onClick={this.toggleDropdown}>Project 2</a>
                <a className="dropdown-item" href="#project3" onClick={this.toggleDropdown}>Project 3</a>
                <a className="dropdown-item" href="#project4" onClick={this.toggleDropdown}>Project 4</a>
                <a className="dropdown-item" href="#project5" onClick={this.toggleDropdown}>Project 5</a>
                <a className="dropdown-item" href="#project6" onClick={this.toggleDropdown}>Project 6</a>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about" onClick={this.closeDropdown}>About</a>
            </li>
          </ul>
         </div>
      </nav>
    )
  }
}
// markup
class IndexPage extends React.Component{
    constructor(props){
    super(props);
    this.state = {sectionHeight: 500,
                  resumeHeight: 500-100,
                  sectionWidth: 500,
                  rotation: 50,
                  project: null
                };
    this.openProject = this.openProject.bind(this)
  }

  componentDidMount() { 
    //set up styles on client side
    console.log(this.state)

    this.setState({rotation: 100});

    this.setState({
      sectionHeight:  window.innerHeight,
      resumeHeight: window.innerHeight-profileImageStyle.marginTop-profileImageStyle.height-100,
      sectionWidth: window.innerWidth
    }, () => {
      console.log(this.state);
    }); 
    this.openProject()
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener(`hashchange`, this.openProject);
  }

  openProject(event){
    let hash = window.location.hash
    console.log(`hash is: ${hash} while last number is: ${hash[hash.length-1]}`)
    if(hash.includes(`project`)){
      this.setState({project:hash[hash.length-1]})
    }else{
      this.setState({project:null})
    }
  }
  
 
  render(){
    let description = "Portfolio, Resume and Contact for Justin Smith"
    return (
      <div>
        <Helmet>
          <html lang='en' />
          <title>Justin D Smith Designer</title>
          <meta name='description' content= "Portfolio, Resume and Contact for Justin Smith" />
          <meta name="viewport" content="width=100%, initial-scale=1, shrink-to-fit=no"></meta>
        </Helmet>
        <div id="home" style={{height:this.state.sectionHeight}}>
          <img src={logo} alt="arrow" style={logoStyles} />
        </div>
        <div id="work" style={{height:this.state.sectionHeight}}>
          <img src={portfolioTag} alt="tag" className="sectionTag" />
        </div>
        {(this.state.project === null) ? null: <Project number={this.state.project} height={(isMobile)? null:this.state.sectionHeight}/>}
        <div id="about" style={{height:this.state.sectionHeight}}>
          <div className="row justify-content-start gx-0">
            <div className="col-md-4">
              <img src={resumeTag} alt="tag" className = "sectionTag" />
            </div>
            <div className="col-md-4">
              <img src={profilePhoto} alt="JS Profile Photo" className = "mx-auto d-block" style={profileImageStyle}/>
            </div>
          </div>
          <div id="resume" className = "resume mx-auto mt-4 p-3" style={{height:this.state.resumeHeight}}>
          <Resume />
          </div>
          <div style={{height:50}}></div>
        </div>
        <div id="blocks" style={pageStyles}>
          <Blocks height={this.state.sectionHeight} width={this.state.sectionWidth} style={{zIndex:1}} />
        </div>
        <div id="animation" style={pageStyles}>
          <AnimateScene height={this.state.sectionHeight}/>
        </div>
        <div>
          <img src={cArrow} alt="arrow" style={cropArrowStyle[0]} />
          <img src={cArrow} alt="arrow" style={cropArrowStyle[1]} />
          <img src={cArrow} alt="arrow" style={cropArrowStyle[2]} />
          <img src={cArrow} alt="arrow" style={cropArrowStyle[3]} />
        </div>
        <NavBar />
        <Footer />
      </div>
    )
  }
}

export default IndexPage
