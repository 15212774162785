import * as React from "react"

import  instaIcon from "../assets/I_Icon.svg"
import  emailIcon from "../assets/E_Icon.svg"
import  linkedIcon from "../assets/LI_Icon.svg"

const iconStyles = {
    height:50,
    padding:10,
}



class Footer extends React.Component{
    componentDidMount() { 
        let containerStyles = `
            height:50px;
            bottom:0px;
            left:${(window.innerWidth/2)-(iconStyles.height*3)/2}px;
            position:fixed;
        `

        document.getElementById("footer").style.cssText = containerStyles
    }

    render(){

        return(
        <div id="footer">
            <a href="https://www.instagram.com/justinxtoronto/">
                <img className = 'icon' src={instaIcon} alt="tag" style={iconStyles} />
            </a>
            <a href="mailto:jds@justindsmith.ca">
                <img className = 'icon' src={emailIcon} alt="tag" style={iconStyles} />
            </a>
            <a href="https://www.linkedin.com/in/justin-smith-arc">
                <img className = 'icon' src={linkedIcon} alt="tag" style={iconStyles} />
            </a>
        </div>
        )
    }
}

export default Footer