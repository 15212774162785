import React from 'react'
import * as THREE from "three"
import MarkerBlocks from './jds_markers'
import Tag from './jds_tags'
import { isMobile } from "react-device-detect";
import JDSKeyframeTrack from './jds_keyframe'

import { VertexNormalsHelper } from 'three/examples/jsm/helpers/VertexNormalsHelper.js';
//const GLTFLoader = require('three-gltf-loader');
import OrbitControls from 'three-orbitcontrols';




class AnimateScene extends React.Component {
  

  constructor(props) {
	  super(props);
    this.state = {
      play: true,
      clicked: `empty`,
    };
    //figure out width
    this.frameWidth = 360
    this.animate = this.animate.bind(this)
    this.onClicked = this.onClicked.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onIntersection = this.onIntersection.bind(this)
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.moving = true;
    this.count = 0;
    this.mobile = false;
    this.clicked = ``;
    this.markers = [];
    this.markerMixers = []
    this.markerMeshMixers = []
    this.loadedMarkers = false
    this.loadedTags = false
    this.zoomScale = (isMobile)?0.56:0.36

    this.color = {
      black : 0x000000,
      reg: 0xDDDDD4,
      white : 0xffffff,
    }

    this.markerAttr = {
      spacing:10,
      x:-90,
      y:-15,
      z:10,
      xCount: (isMobile)?1:3,
      yCount: (isMobile)?3:2,
      shift: 15,//15
      on: 10,//10
    };

    
    this.frustumSize = 125

	}

  componentDidMount() {
    this.aspect = window.innerWidth / window.innerHeight;
    this.camerawidth =  (this.frustumSize * this.aspect) / 2
    this.cameraheight = this.frustumSize/2

    console.log(`camera width is ${this.camerawidth} and height is${this.cameraheight}`)
    
    this.mytest = new MarkerBlocks(this.markerAttr.xCount,this.markerAttr.yCount);
    this.myPortfolioTag = new Tag(`tagPortfolio`,'../../TAG_PORTFOLIO.svg', this.camerawidth)
    this.myResumeTag = new Tag(`tagResume`,'../../TAG_RESUME.svg', this.camerawidth)

    this.websiteKT = new JDSKeyframeTrack([0,1,1,0],[0,2,3,4])

    
    this.renderer = new THREE.WebGLRenderer( { alpha: true } );
    this.clock = new THREE.Clock();

    const container = document.getElementById("animation")

    this.renderer.setSize(container.clientWidth, container.clientHeight)

    container.appendChild(this.renderer.domElement)
    container.addEventListener( 'mousemove', this.onMouseMove, false);
    container.addEventListener( 'click', this.onClicked, false);
    container.style.position = 'fixed';
    
    this.scene = new THREE.Scene()
    //this.camera = new THREE.PerspectiveCamera( 45, this.aspect, 1, 10000 );

 //Start Camera

    this.camera = new THREE.OrthographicCamera( 
      this.frustumSize * this.aspect / - 2, 
      this.frustumSize * this.aspect / 2, 
      this.frustumSize / 2, 
      this.frustumSize / - 2, 
      0, 
      1000);
    this.camera.position.set( 0, 150, 0);//Start Position
    this.camera.rotation.set(-Math.PI/2,0,0)

    const cameraPositionKF = new THREE.VectorKeyframeTrack( '.position', [0,2,3,4],   
      [0, 150, 0, 
      this.mytest.centerPoint[0],this.mytest.centerPoint[1], this.mytest.centerPoint[2],
      this.mytest.centerPoint[0],this.mytest.centerPoint[1], this.mytest.centerPoint[2],
      0, 150, 0, ]
       );

      const cameraScaleKF = new THREE.VectorKeyframeTrack( '.scale', [0,2,3,4],
       [1,1,1,
        this.zoomScale,this.zoomScale,this.zoomScale,
        this.zoomScale,this.zoomScale,this.zoomScale,
        1,1,1,
       ]);

    const cameraClip = new THREE.AnimationClip( 'Camera_Action', -1, [ cameraPositionKF, cameraScaleKF ] );
    this.cameraMixer = new THREE.AnimationMixer( this.camera );
    

    // create a ClipAction and set it to play
    const cameraAction = this.cameraMixer.clipAction( cameraClip );
    cameraAction.setLoop(THREE.LoopPingPong,100)
    cameraAction.play();


  
    
    this.mytest.innerMarkers.forEach(element =>{
      if(element.pop.group){
        this.scene.add(element.pop.group)
      }
    })


    this.opacitypopMixer = this.mytest.popMixer;
    this.opacityMixer = this.mytest.opacityMixer
    this.markerMeshMixers = this.mytest.movementMixers
    //console.log(this.opacitypopMixer)
    
    //this.markerMesh = this.createMarkerMesh()
    this.markerMesh = this.mytest.group()
    this.scene.add(this.markerMesh)

    //this.controls = new OrbitControls( this.camera, this.renderer.domElement );
    this.animate()
  }

  onMouseMove( event ) {
    this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
	  this.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1; 
  }

  onClicked(event){
    console.log(`clicked :${this.state.clicked}`)
    if(this.state.clicked !== null){
      window.location.hash = this.state.clicked;
    }
  }

  onIntersection(intersects){
    if(intersects.length===0){
      document.body.style.cursor = "auto"
      this.setState({clicked: null})
    }

    for ( let i = 0; i < intersects.length; i ++ ) {
      let intersect = intersects[i]
      let intersectname = intersect.object.parent.name
      if(intersectname.includes("plane") === false){
        if(intersectname===`tagResume`){
          document.body.style.cursor = "pointer"
          this.myResumeTag.hover()
          this.setState({clicked:`about`})
        }else if(intersectname===`tagPortfolio`){
          document.body.style.cursor = "pointer"
          this.myPortfolioTag.hover()
          this.setState({clicked:`work`})
        }else if(intersectname.includes("project")){
          //console.log(intersect)
          document.body.style.cursor = "pointer"
          let num = intersectname.charAt(intersectname.length-1)
          this.setState({clicked:`project${num}`})
        }else{
          document.body.style.cursor = "auto"
        }
      }else{
        document.body.style.cursor = "auto"
        //this.setState({clicked: null})
      }


    }
  }

  animateMorpTargets=(array,float)=>{
    let morphTargets = array
    let range = float - Math.floor(float)
    let maxrange = 1/morphTargets.length
    let startRange = Math.floor(range/maxrange)
    let location = (range - startRange*maxrange)/maxrange
    //console.log(`max range is:${maxrange} while start range is:${startRange} and last location is:${location}`)
    let morphed = []
    for (let i = 0; i < morphTargets.length; i++) {
      if(i===startRange){
        morphed.push(location)
      }else if(i===startRange-1){
        morphed.push(1-location)
      }else{
        morphed.push(0)
      }
    }
    //console.log(morphed)
    return morphed
  }

  animate=()=>{
    let delta = this.clock.getDelta();
    this.count = delta+this.count
    this.renderer.render(this.scene, this.camera)

    this.mytest.update()

    
    let progress = () =>{ 
      let fraction = window.pageYOffset/(window.innerHeight*2)
      if(fraction>=1){
        fraction = 0.999999999
      }else if(fraction===0){
        fraction = 0.00000001
      }
      let progress = 4*fraction
      return progress
    }

    let fullSheetProgress = progress()
    let scale = this.websiteKT.findTimeValue(fullSheetProgress)

    //load targets in class
    if(!this.loadedMarkers){
      this.mytest.getInnerArray(this.mytest.markers).forEach(element=>{
        if(element.targetLocationMixer && element.targetMesh){
          //console.log(element.targetMesh)
          this.markerMixers.push(element.targetLocationMixer)
          this.scene.add(element.targetMesh)
          this.loadedMarkers = true
        }
      });
    }else{
      //this.mytest.animateTarget(3,delta)
    }

    if(!this.loadedTags){
      if(this.myPortfolioTag.group && this.myResumeTag.group){
        this.myResumeTag.group.position.x = 100
        this.myResumeTag.group.position.z = 10
        this.myPortfolioTag.group.position.z = -30

        this.scene.add(this.myPortfolioTag.group)
        this.scene.add(this.myResumeTag.group)
        this.loadedTags = true
      }
    }else{
      this.myResumeTag.animate(delta)
      this.myResumeTag.transparency(scale)
      this.myPortfolioTag.animate(delta)
      this.myPortfolioTag.transparency(scale)
    }

    // update the picking ray with the camera and mouse position
	  this.raycaster.setFromCamera( this.mouse, this.camera );

    // calculate objects intersecting the picking ray
    const intersects = this.raycaster.intersectObjects( this.scene.children, true );
    if(intersects.length>0){
      intersects.forEach(element => {
        let name = element.object.name
        //console.log(`element:${name}`)
        if(name){
          var item = name.split("_");
          if(name.includes(`project`)&&name.includes(`face`)){
            let itemNum = parseInt(item[item.length-1])
            //console.log(itemNum)
            if(this.loadedMarkers){
              this.mytest.animateTarget(itemNum-1,delta)
            }
            }
          }
      });
    }

    this.onIntersection(intersects)
    


    //Animate Sides of Blocks
    if(this.loadedMarkers){
      this.mytest.animateMeshs(fullSheetProgress)
      //console.log(`site progress is ${fullSheetProgress} while animation progress is ${scale}`)
      this.mytest.animateTargets(scale, 0.2+0.8*scale)
    }

    if ( this.cameraMixer ) {
      this.cameraMixer.setTime(fullSheetProgress)
    }

    if ( this.opacityMixer ) {
      this.opacityMixer.setTime(fullSheetProgress)
    }

    if(this.opacitypopMixer){
      this.opacitypopMixer.setTime(fullSheetProgress)
    }

    //Targets Position
    if ( this.markerMixers.length>0) {
      this.markerMixers.forEach(element => {
        element.setTime(fullSheetProgress)
      });
    }

    //Blocks Position
    if ( this.markerMeshMixers.length>0) {
      this.markerMeshMixers.forEach(element => {
        element.setTime(fullSheetProgress)
      });
    }
    
    requestAnimationFrame(this.animate)

    }

  render() {
    return (
        <div ref={ref => (this.mount = ref)}  />
        )
  }

}

export default AnimateScene