import * as THREE from "three"
import {SVGLoader} from 'three/examples/jsm/loaders/SVGLoader.js';

const svgloader = new SVGLoader();

class Tag{
    constructor(name,file,width){
        this.loadSVG = this.loadSVG.bind(this)

        this.name = name;
        this.fileName = file;

        this.width = width*2
        this.loaded = this.load(this.fileName)
        this.group = null
        this.play = true
    }

    load=(file)=>{
        svgloader.load(
            // resource URL
            file,
            // called when the resource is loaded
            (data)=> {
                let tag =  this.loadSVG(data)
                tag.name = this.name
                this.group =  tag
            },
            // called when loading is in progresses
            function ( xhr ) {
              console.log( ( xhr.loaded / xhr.total * 100 ) + `% tag loaded`);
            },
            // called when loading has errors
            function ( error ) {
              console.log( 'An error happened with svg', error );
            }
          );

          return true

    }

    loadSVG(data){
        let paths = data.paths;
        let group = new THREE.Group();

        //console.log(`shapes:`)
        //console.log(paths)
        
        for ( let i = 0; i < paths.length; i ++ ) {
    
          let path = paths[ i ];
    
          let material = new THREE.MeshBasicMaterial( {
            color: path.color,
            side: THREE.DoubleSide,
            depthWrite: false
          } );
    
          let shapes = SVGLoader.createShapes( path );
          
          //console.log(shapes)
    
          for ( let j = 0; j < shapes.length; j ++ ) {
            let shape = shapes[ j ];
            let geometry = new THREE.ShapeGeometry( shape );
            let mesh = new THREE.Mesh( geometry, material );
            
            mesh.scale.set(0.07,0.07,0.07)
            mesh.position.z = -50
            
            let mesh2 = mesh.clone()
            mesh2.position.set(this.width+this.width/4,0,0)
    
            group.add(mesh);
            group.add(mesh2);
            group.rotation.set(Math.PI/2,0,0)
          }
    
        }
        //console.log(group)
        return group
      }

    animate(delta,speed=10){
      let progress = delta*speed
      if(this.play){
        if(this.group.position.x < -this.width-this.width/4){
          console.log(`reset location ${this.name}`)
          this.group.position.x =0
        }
        else{
          this.group.position.x = this.group.position.x-progress
        }
      }else{
        this.play = true
      }
    }

    transparency(progress){
      this.group.children.forEach(element => {
        element.material.opacity = 1-progress
      });
    }

    hover(){
      this.play = false
    }

}

export default Tag