import * as React from "react"



class Resume extends React.Component{
render(){
    return(
        <div style = {{position:"relative",zIndex:`3`}}>
            <div className = 'resumeIntro' style={{}}>
                <h6>Intermediate Intern Architect, +5 Years of professional experience. Able to bridge architecture and emerging technologies. Experience in the architectural field spans across all phases of a project, giving the ability to work in various roles and efficiently complete projects. Skills encompass an understanding of advanced modelling, computer programming and digital manufacturing allowing the ability to find and improve existing procedures and expand creativity.
                </h6>
            </div>
            <div className="row" style={{marginTop:'1em'}}>
                <div className="col-sm-4 ">
                <h4>Revit</h4>
                BIM Modelling<br />
                </div>
                <div className="col-lg-8 ">
                <ul>
                <li>Executed CD packages while maintaining model quality to create VR experiences in Twin Motion.
                </li><li>Able to create model families and annotation families; increasing design efficiency and drawing set clarity.
                </li><li>Work is organized and neat, using up to date standards, worksets, and options to allow for seamless collaboration between team.
                </li><li>Able to program Dynamo Scripts to track and record building information to allow for faster and automated information collection.
                </li>
                </ul>
                </div>
            </div>
            <div className="row" style={{marginTop:'1em'}}>
                <div className="col-sm-4 ">
                <h4>Digital Fabrication</h4>
                Lab Technician<br />
                </div>
                <div className="col-lg-8 ">
                <ul>
                <li>Able to execute various creative projects using 3D Printing, CNC, and Laser Cutting machinery while ensuring the best possible results.
                </li><li>Able to model and fix 3D Geometry to achieve the best possible results from digital fabrication machines.
                </li><li>Able to manage different scales of projects from models, installations to envelope screens to the necessary fabrication tools appropriate for its scale.
                </li>
                </ul>
                </div>
            </div>
            <div className="row" style={{marginTop:'1em'}}>
                <div className="col-sm-4 ">
                <h4>Rhino</h4>
                Parametric Modelling<br />
                </div>
                <div className="col-lg-8 ">
                <ul>
                <li>Able to create complex geometry using a variety of techniques from NURBS, meshes, to tsplines [quad mesh].
                </li><li>Able to utilize grasshopper and python to create parametric and generative designs.
                </li><li>Use Rhino to create infographic diagrams rendered in VRAY to communicate building concepts.
                </li>
                </ul>
                </div>
            </div>
            <div className="row" style={{marginTop:'1em'}}>
                <div className="col-sm-4 ">
                <h4>Web Development</h4>
                Front End Web Development<br />
                </div>
                <div className="col-lg-8 ">
                <ul>
                <li>Script in JavaScript and Python able to read and write in both languages.
                </li><li>Able to Web Develop using React Framework as well as Gatsby Web Framework.
                </li><li>Able to create interactive 3D dimensional web content using Three.JS
                </li><li>Able to create interactive and generative 2D dimensional content using Processing or Canvas library
                </li>
                </ul>
                </div>
            </div>
            <div className="row" style={{marginTop:'1em',paddingTop:`0.5em` ,borderTop: `2px solid black`, borderBottom: `2px solid black`}}>
                <div className="col-md-6 ">
                    <ul>
                        <li>Completed			OAA Exam
                        </li><li>Masters of Architecture	University of Toronto 
                        </li><li>1 Year - Program		Sci Arc
                        </li>
                    </ul>
                </div>
                <div className="col-md-6 ">
                    <ul>
                        <li>Bachelor of Architectural Sci.	Carleton University
                        </li><li>Competent Communication 	Go-Getters
                        </li><li>Intro to Electronics		Interaccess
                            </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
}


export default Resume