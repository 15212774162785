import React from 'react'
import { isMobile } from "react-device-detect";

class Blocks extends React.Component{
    constructor(props) {
        super(props);

        this.getRndInteger = this.getRndInteger.bind(this)

        this.rowsCount = (isMobile)?10:15
        this.columnsCount = 3
        this.maxLength = 300
        this.minLength = (isMobile)?150:100
        this.width = 20
        this.height = this.props.height*3
        this.cutoff = (isMobile)?0:this.props.height*2
      }


    componentDidMount(){
        
        var c = document.getElementById("blocksCanvas");
        var container = document.getElementById("blocks");
        c.height = container.clientHeight*3
        c.width = container.clientWidth
        var ctx = c.getContext("2d");
        let rowHeight = parseInt(c.height/this.rowsCount)
        let columnWidth = parseInt(c.width/this.columnsCount)

        
        for (let i = 0; i < this.rowsCount; i++) {
            for (let h = 0; h < this.columnsCount; h++) {
                let xDis = this.getRndInteger(0,columnWidth/4)
                let yDis = this.getRndInteger(0,rowHeight-this.width)
                let xPos = h*columnWidth + xDis
                let yPos = i*rowHeight + yDis
                if(yPos<this.cutoff){
                    if(Math.random()>0.7){
                        ctx.fillRect(xPos,yPos, this.getRndInteger(this.minLength, columnWidth/2), this.width);
                    }
                }else if(yPos>this.cutoff*2){
                    if(h!==1){
                        if(Math.random()>0.7){
                            ctx.fillRect(xPos,yPos, this.getRndInteger(this.minLength, columnWidth/2), this.width);
                        }
                    }
                }
            }
        }
        console.log(ctx)
    }

    getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min) ) + min;
    }

    render(){
        return(
            <canvas id="blocksCanvas" style={{position:'absolute', top:`0`}}></canvas>
        )
    }
}

export default Blocks