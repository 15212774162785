class JDSKeyframeTrack{
    constructor(valueArray, timeArray){
        this.valueArray = valueArray
        this.timeArray = timeArray
        this.totalTime = [timeArray.length-1]
        this.value = 0;
        this.scale = 0;
        //this.findTimeValue = this.findTimeValue.bind(this)
    }

    findScaleValue(scale){
        let currentTime = this.totalTime*scale
        let startTimeRange,endTimeRange,startValueRange,endValueRange
        for (let i = 0; i < this.timeArray.length; i++) {
            if(currentTime>this.timeArray[i]){
                startTimeRange = this.timeArray[i]
                endTimeRange = this.timeArray[i+1]
                startValueRange = this.valueArray[i]
                endValueRange = this.valueArray[i+1]
            }
        }
        let rangeTimeScale = (currentTime-startTimeRange)/(endTimeRange-startTimeRange)
        let rangeValue = endValueRange-startValueRange
        let value = rangeTimeScale*rangeValue+startValueRange
        this.value = value
        return this.value
    }

    findTimeValue(time){
        let currentTime = time
        let startTimeRange,endTimeRange,startValueRange,endValueRange
        for (let i = 0; i < this.timeArray.length; i++) {
            if(currentTime>this.timeArray[i]){
                startTimeRange = this.timeArray[i]
                endTimeRange = this.timeArray[i+1]
                startValueRange = this.valueArray[i]
                endValueRange = this.valueArray[i+1]
            }
        }
        let rangeTimeScale = (currentTime-startTimeRange)/(endTimeRange-startTimeRange)
        let rangeValue = endValueRange-startValueRange
        let value = rangeTimeScale*rangeValue+startValueRange
        this.value = value
        return this.value
    }
}

export default JDSKeyframeTrack