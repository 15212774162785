import React from 'react'
import { isMobile } from "react-device-detect";
import { useStaticQuery, graphql } from 'gatsby'

const Project = (props) =>{

    let colors = {
        black : `black`,
        reg: 0xDDDDD4,
        white : `white`,
    }

    var divID = `project${props.number}`

    const data = useStaticQuery(graphql`
        query FindProject {
            allMarkdownRemark {
            nodes {
                frontmatter {
                    slug
                    title
                    date
                    type
                    featuretype
                    featureurl
                    process
                }
                html
            }
            }
        }
    `)
    
    let count = 0
    let foundProjectNum = 0

    for (const node of data.allMarkdownRemark.nodes) {
        
        let slug = node.frontmatter.slug;
        let num = slug.charAt(slug.length - 1)
        //console.log(`looking for ${props.number} and comparing to ${num}`)
        if(num === props.number){
            //console.log(found)
            foundProjectNum = count
        }
        count++
    }

    let { title, type, date, featuretype,featureurl, process } = data.allMarkdownRemark.nodes[foundProjectNum].frontmatter;
    let html = data.allMarkdownRemark.nodes[foundProjectNum].html
    
    //get all images
    var img_urls = []
    for (let i = 0; i < process; i++){
        let url = `/images/project${props.number}/Project_0${props.number}_Process_0${i}.jpg`
        img_urls.push(url)
    }

    var feature = "empty"

    if (featuretype==="image") {
        feature = <img src={featureurl} alt={`Feature Image_${title}`} style={{objectFit:`cover`, padding:`0.2em`,aspectRatio:`2`}}/>
    }else if(featuretype==="iframe"){
        feature = <iframe height="280"  style={{zIndex:2}} src="https://www.youtube.com/embed/0UEAygKqxQo?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen></iframe>
    }

    console.log(`the current feature is an ${feature} while the feature type is ${featuretype} for the current tite ${title}`)

    return(
        <div id={divID} style={{height:props.height,paddingTop:100}}> 
            <div className="container">
                <div className="row">
                <div className="col-lg-5">
                    <div className="row p-2 m-2" id="project_featureIMG" style={{background:colors.black, height:292}}>
                    {feature}
                    </div>
                    {isMobile 
                        ?<div className="row m-2" style={{background:colors.white, height:150}}>
                            <img src={`/images/project${props.number}/project_title_0${props.number}.jpg`} alt={`Nametag_${title}`} style={{objectFit:`cover`, padding:`0`,aspectRatio:`2`,filter:`invert(1)`,height:`150px`}}/>
                        </div>
                        :null
                    }
                </div>
                <div className="col-sm">
                    <div className="row p-2 m-2" id="project_thumbnailIMG" style={{background:colors.black, height:292}}>
                        <img src={`/images/project${props.number}/Project_0${props.number}_Thumbnail.jpg`} alt={`Thumbnail Image_${title}`} style={{objectFit:`cover`,padding:`0.2em`,aspectRatio:`2`}} />
                    </div>
                    <div className="row p-2 m-2" id="project_desecription" dangerouslySetInnerHTML={{ __html: html }} style={{background:colors.white,position:`relative` ,overflowY:`scroll`,height:272, zIndex:`3`}}>
                    </div>
                </div>
                <div className="col-sm">
                    {!isMobile 
                        ?<div className="row m-2" style={{background:colors.white, height:150}}>
                            <img src={`/images/project${props.number}/project_title_0${props.number}.jpg`} alt={`Nametag_${title}`} style={{objectFit:`cover`, padding:`0`,aspectRatio:`2`,filter:`invert(1)`,height:`150px`}}/>
                        </div>
                        :null
                    }
                    <div className="row p-2 m-2" style={{position:`relative` ,background:colors.black, overflowY:`scroll`,height:405, zIndex:`3`}}>
                        {img_urls.map((url, index) => (
                            <img src={url} className="process_img" alt={`Process Image_${index}`} key={index} style={{padding:`0.2em`,aspectRatio:`2`}} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
            {/* <div style={{padding:10,writingMode:`vertical-lr`}}>
            project_{props.number}_test_portal
            </ div> */}
        </div>
    )
    
}


export default Project