import * as THREE from "three"

import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import { isMobile } from "react-device-detect";

const loader = new GLTFLoader();

const markerAttr = {spacing:10,
  x:(isMobile)?-40:-90,
  y:-15,
  z:10,
  shift: 5,//15
  on: 10,//10
}

const scrOff = {
  x:111,
  y:62.5
}

let locationMethod = (x,y) => {
  let sposX = markerAttr.x+x*markerAttr.spacing
  let sposY = markerAttr.y // Move a bit to hold back
  let sposZ = markerAttr.z+y*markerAttr.spacing

  let eposX = sposX + (markerAttr.shift+markerAttr.spacing)*y + (markerAttr.shift+markerAttr.spacing)*x

  return [sposX,sposY,sposZ,eposX,sposY,sposZ,eposX,sposY,sposZ,sposX,sposY,sposZ,]

}

class MarkerBlock{
  constructor(locX, locY, blank){
    this.x = locX;
    this.y = locY;
    this.blank = blank
    this.info = this.getInfo()
    this.colors = {
      black : 0x000000,
      reg: 0xDDDDD4,
      white : 0xffffff,
    };
    this.clip = this.animationClip()
    let mesh = this.createMesh()
    this.mesh = mesh
    this.loaded = false;
    this.updated = false;
    this.pr_img_aspect = 0;
    
    
    //Animate Mesh Sides Grounds
    let sideMorphKF = new THREE.NumberKeyframeTrack(`.morphTargetInfluences`,[0,2,3,4],[0,1,1,0])
    this.sideMorphClip = new THREE.AnimationClip( 'side_morph_Action', -1, [sideMorphKF] );
    this.sideMorphGroup = new THREE.AnimationObjectGroup(this.mesh.children[1],this.mesh.children[2])
    this.sideMorphMixer = new THREE.AnimationMixer(this.sideMorphGroup);
    this.sideMorphAction = this.sideMorphMixer.clipAction( this.sideMorphClip );
    this.sideMorphAction.setLoop(THREE.LoopPingPong,100)
    this.sideMorphAction.play();
  }

  getInfo=()=>{
    let blank = this.blank?`project_${this.blank}`:`blank`
    let info = `x${this.x}y${this.y}_${blank}`
    return info
  }



  //Locating Marker
  animationClip=()=>{
        //Figure Out Locations Better
        let clipLocations = locationMethod(this.x,this.y)
        //console.log(clipLocations)

        let movement_KF = new THREE.VectorKeyframeTrack('.position', [0,2,3,4], clipLocations)
        let movement_Clip = new THREE.AnimationClip(`markerAnimationClip_${this.info}`,-1,[movement_KF])
    return movement_Clip
  }

  createMesh=()=>{
    var groupMarker = new THREE.Group();

    let plane_faceGeo = new THREE.PlaneGeometry(markerAttr.spacing,markerAttr.spacing)
    let plane_topGeo  = new THREE.PlaneGeometry(markerAttr.spacing,markerAttr.spacing)
    let plane_sideGeo = new THREE.PlaneGeometry(markerAttr.spacing,markerAttr.spacing)

    plane_faceGeo.rotateX (Math.PI/2 )
    plane_sideGeo.rotateY (Math.PI/2 )
    
    plane_topGeo.translate(0,-markerAttr.spacing/2-0.1,-markerAttr.spacing/2)
    plane_sideGeo.translate(-markerAttr.spacing/2,-markerAttr.spacing/2-0.1,0)
    plane_faceGeo.translate(0,-0.1,0)

    //Top Morph
    plane_topGeo.morphAttributes.position = [];
    
    let plane_topMorphPoints = [
      0,0,0,
      0,0,0,
      -markerAttr.shift,0,-markerAttr.spacing,
      -markerAttr.shift,0,-markerAttr.spacing,
    ]

    //plane_topGeo.morphAttributes.position[ 0 ] = new THREE.BufferAttribute (Float32Array.from(plane_topMorphPoints),3)
    plane_topGeo.morphAttributes.position[ 0 ] =  new THREE.Float32BufferAttribute(plane_topMorphPoints,3)
    plane_topGeo.morphTargetsRelative = true


    //Side Morph
    plane_sideGeo.morphAttributes.position = [];
    
    let plane_sideMorphPoints = [
      0,0,0,
      0,0,0,
      -markerAttr.shift,0,-markerAttr.spacing,
      -markerAttr.shift,0,-markerAttr.spacing,
    ]

    //plane_sideGeo.morphAttributes.position[ 0 ] = new THREE.BufferAttribute (Float32Array.from(plane_sideMorphPoints),3)
    plane_sideGeo.morphAttributes.position[ 0 ] = new THREE.Float32BufferAttribute( plane_sideMorphPoints, 3 )
    plane_sideGeo.morphTargetsRelative = true
    
    let materialB 

    if(this.blank){
      let texture1 = new THREE.TextureLoader().load( `/project_list.jpg` );
      texture1.mapping = THREE.RepeatWrapping;
      texture1.wrapT = texture1.wrapS = THREE.RepeatWrapping; 
      //texture1.flipY = false;
      texture1.rotation = Math.PI/2
      texture1.repeat.y = -1/6;
      texture1.offset.y = (this.blank-1)/6;
      //console.log(texture1)
      materialB   = new THREE.MeshBasicMaterial( {color: 0xffffff,  map: texture1, side: THREE.DoubleSide, transparent: true, morphTargets: true } );
      //materialW.needsUpdate = true;
    }else{
      materialB = new THREE.MeshBasicMaterial( { color: this.colors.black, side: THREE.DoubleSide, transparent: true, morphTargets: true } );
    }
    let materialW

    if(this.blank){
        //need to clean up this blank
        let url = `/images/project${this.blank}/Project_0${this.blank}_Thumbnail.jpg`
        let texture = new THREE.TextureLoader().load( url, texture=>{
          let imgWidth = texture.image.width // object
          let imgHeight = texture.image.height
          this.pr_img_aspect = imgWidth/imgHeight
          this.loaded = true
          //console.log(`in load aspect is ${this.pr_img_aspect}`)
        })
      texture.mapping = THREE.RepeatWrapping;
      texture.wrapT = texture.wrapS = THREE.RepeatWrapping; 
      texture.flipY = false;
      materialW  = new THREE.MeshBasicMaterial( {color: 0xffffff,  map: texture, side: THREE.DoubleSide, transparent: true, morphTargets: true } );
      //materialW.needsUpdate = true;
    }else{
      materialW = new THREE.MeshBasicMaterial( { color: this.colors.white, side: THREE.DoubleSide, transparent: true, morphTargets: true } );
    }
    let material = new THREE.MeshBasicMaterial( { color: this.randomColor(), side: THREE.DoubleSide, transparent: true, morphTargets: true } );

    let plane_face = new THREE.Mesh( plane_faceGeo, materialW );
    let plane_top = new THREE.Mesh( plane_topGeo, materialB );
    let plane_side = new THREE.Mesh( plane_sideGeo, material );

    plane_face.name = `face_${this.info}`
    plane_top.name = `top_${this.info}`
    plane_side.name = `side_${this.info}`

    //let random = this.createGeometry()
    //console.log(random)

    // Morph Planes
    plane_top.morphTargetInfluences[0] = 0.99;
    plane_side.morphTargetInfluences[0] = 0.99;
    //random.morphTargetInfluences[0] = 0.5;


    groupMarker.add(plane_face, plane_top, plane_side)
    groupMarker.name = `markerBox_${this.info}`

    return groupMarker
  }

  randomColor=()=>{
    let colors = [this.colors.black, this.colors.white, this.colors.reg]
    let random = colors[Math.floor(Math.random()*colors.length)]
    return random
  }

  animateMesh(float){
    this.sideMorphMixer.setTime(float)
  }

  update(){
    if(!this.updated && this.loaded){
      //console.log(`get this item texture at aspect${this.pr_img_aspect}`)
      //console.log(this.mesh.children[0].material.map)
      //what to do if portrait image??
      this.mesh.children[0].material.map.repeat.x = 1/this.pr_img_aspect
      this.mesh.children[0].material.map.offset.x = 1/this.pr_img_aspect
      this.mesh.children[0].material.map.repeat.y = 1
      //console.log(this.mesh.children[0].material.map)
      this.updated = true
    }
  }

  

}

class MarkerBlockBlank extends MarkerBlock{
  constructor(locX, locY){
    super(locX,locY,false);
  }
}

class MarkerBlockProject extends MarkerBlock{
  constructor(locX, locY, projectTitle){
    super(locX,locY,projectTitle);
    this.project = projectTitle;
    this.loadedMarker = this.loadTarget()
    this.targetMesh = null; 
    this.targetLocationMixer = null;
    this.targetAnimationMixer = null;
    let pop = new MarkerProjectPop(locX,locY,projectTitle)
    pop.toggleVisible()
    this.pop = pop
    this.targetCurrentTime = 0
    this.startScale = 0
    this.endScale = 0.2
    this.direction = 1
  }

  loadTarget=()=>{
    loader.load(
      '../../Marker.gltf',
      ( gltf ) => {
            
            this.targetMesh = this.loadModel( gltf,this.project)[0]
            this.targetLocationMixer = this.loadModel( gltf,this.project)[1] 
            console.log(`added...`)
      },
      ( xhr ) => {
        // called while loading is progressing
        console.log( `${( xhr.loaded / xhr.total * 100 )}% loaded targets` );
      },
      ( error ) => {
        // called when loading has errors
        console.error( 'An error happened with gltf', error );
      },
    );

    return true
  }

  loadModel(model){
    let modelObject = model.scene.children[0].clone()
    modelObject.scale.set(0.95,0.95,0.95)
    modelObject.name = `target_${this.info}`

    let modelClip = model.animations[0]
    modelObject.children.forEach(element => {
      element.morphTargetInfluences = this.animateMorphTargets(element.morphTargetInfluences,0)
    });

    this.targetMesh = modelObject
    
    let markerLocationMixer = new THREE.AnimationMixer(this.targetMesh)
    let clipLocationAction = markerLocationMixer.clipAction(this.clip)
    clipLocationAction.play()
    markerLocationMixer.addEventListener('finished',( e ) => {console.log("finished loop")}) 
    markerLocationMixer.name = `location mixer`
    this.targetLocationMixer  = markerLocationMixer
    //console.log(this.clip)


    let markerAnimationMixer = new THREE.AnimationMixer(this.targetMesh)
    let clipAnimationAction = markerAnimationMixer.clipAction(modelClip)
    clipAnimationAction.play()
    markerAnimationMixer.addEventListener('finished',( e ) => {console.log("finished loop")}) 
    markerAnimationMixer.name = `animation mixer`
    this.targetAnimationMixer  = markerAnimationMixer
    //console.log(modelClip)
    
    return [modelObject, markerLocationMixer]
    
  }
  
  animateMorphTargets=(array,float)=>{
    let morphTargets = array
    let range = (float - Math.floor(float))*(1-this.startScale) + this.startScale
    let maxrange = 1/morphTargets.length
    let inSectionNum = Math.floor(range/maxrange)

    //let inSectionNum = 0.1
    let location = (range - inSectionNum*maxrange)/maxrange

    //console.log(`max range is:${maxrange} in section:${inSectionNum} and last location is:${location}`)
    let morphed = []
    for (let i = 0; i < morphTargets.length; i++) {
      if(i===inSectionNum){
        morphed.push(location)
      }else if(i===inSectionNum-1){
        morphed.push(1-location)
      }else{
        morphed.push(0)
      }
    }
    //console.log(morphed)
    return morphed
  }

  animateTargetsRange(float1,float2){
    this.startScale = float1
    //As start retreats how to retreat end scale???
    this.endScale = float2
    if(this.targetMesh){
      this.targetMesh.children.forEach(element => {
        element.morphTargetInfluences = this.animateMorphTargets(element.morphTargetInfluences,this.targetCurrentTime)
      });
    }
  }

  animateTargets(float){
    if(this.startScale < 0.9){
      if(this.targetCurrentTime<=this.endScale){
        this.targetCurrentTime = this.targetCurrentTime + ((1/(1-this.startScale))*float/3)*this.direction
      }
      this.targetMesh.children.forEach(element => {
        element.morphTargetInfluences = this.animateMorphTargets(element.morphTargetInfluences,this.targetCurrentTime)
      });
    }

    //updated direction
    if (this.targetCurrentTime>this.endScale){
      this.targetCurrentTime=this.endScale
      this.direction = -1
    }else if(this.targetCurrentTime<=0){
      this.targetCurrentTime = 0
      this.direction = 1
    }
  }

}

class MarkerProjectPop{
  constructor(locX, locY, projectTitle){
    this.locX = locX
    this.locY = locY
    this.project = projectTitle;
    this.imageMesh = this.imageMesh()
    this.backgroundMesh = this.backgroundMesh(this.imageMesh)
    let group = this.group(this.imageMesh,this.backgroundMesh)
    this.group = group
  }

  poplocations = (len) => {
    let columns = 3
    let rows = 3
    let totalarray = []
  
    let i = 0
  
    for (let c = 0; c < columns; c++) {
      for (let r = 0; r < rows; r++) {
        let array = []
        let sposX = c*(scrOff.x/columns)*2-scrOff.x+scrOff.x/columns
        let sposY = markerAttr.y+5
        let sposZ = r*(scrOff.y/rows)*2-scrOff.y+scrOff.y/rows     
        if(i<len){
          if(c!==0 || r!==2){
            array.push(sposX,sposY,sposZ)
            totalarray.push(array)
            i++
          }
        }
      }
    }
    return totalarray
  }

  imageMesh=()=>{
    let url = `/images/project${this.blank}/Project_0${this.blank}_Thumbnail.jpg`
    let texture = new THREE.TextureLoader().load(url);
    //console.log(`find photo @: ${url}`) 
    let material = new THREE.MeshBasicMaterial( {color: 0xffffff,  map: texture, transparent: true} );
    //console.log(`pop up for ${this.project}`)
    //let location = poplocationMethod(this.locX,this.locY)
    let locations = this.poplocations(6)
    //console.log(locations)
    let location = locations[this.project-1]
    //console.log(location)
    let plane = new THREE.PlaneGeometry(50,30)
    let planePreview = new THREE.Mesh(plane,material)
    planePreview.rotation.set(-Math.PI/2,0,0)
    //task - create some options of where to locate based on if mobile or not
    //console.log(location)
    planePreview.position.set(location[0],location[1],location[2])
    planePreview.name = `PlanePreview_${this.project}`
    return planePreview
  }

  backgroundMesh=(plane)=>{
    let zoomGeoArray = plane.geometry.attributes.position.array
    let planePoints = []
    let geoPoints = []
    

    for (let i = 0; i < zoomGeoArray.length; i=i+3) {
      let elementx = zoomGeoArray[i]+plane.position.x;
      let elementz = zoomGeoArray[i+1]+plane.position.z;
      let elementy = zoomGeoArray[i+2]+plane.position.y;
      planePoints.push(elementx)
      planePoints.push(elementy)
      planePoints.push(elementz)
    }
    //task-probably not going to work need actual location
    let posX = markerAttr.x+this.locX*markerAttr.spacing
    let posY = markerAttr.y // Move a bit to hold back
    let posZ = markerAttr.z+this.locY*markerAttr.spacing
    planePoints = planePoints.concat([posX,posY+1,posZ])

    let indicesOfFaces = [
      0,1,4,    1,2,4,
      2,3,4,    0,3,4,
    ];

    //console.log(planePoints)

    geoPoints = Float32Array.from(planePoints)
    //console.log(geoPoints)
    
    let geometry = new THREE.BufferGeometry()
    geometry.name = `PopBackgroundGeo_${this.project}`
    geometry.setIndex( indicesOfFaces );
    geometry.setAttribute('position',new THREE.BufferAttribute( geoPoints, 3 ));

    let material = new THREE.MeshBasicMaterial({color: 0xffffff,side: THREE.DoubleSide, transparent: true})
    let mesh = new THREE.Mesh(geometry,material)
    mesh.name = `PopBackground_${this.project}`

  return mesh
  }

  group=(objA,objB)=>{
    const group = new THREE.Group();
    group.add( objA );
    group.add( objB );
    group.name = `PopGroupPreview_${this.project}`
    return group
  }

  toggleVisible(){
    this.group.visible = !this.group.visible
  }

}

class MarkerBlocks{
  constructor(columns, rows) {
    this.rows = rows;
    this.columns = columns;
    this.fetchMarkers = this.createMarkerArray()
    this.markersInfo = this.fetchMarkers[0];
    this.markers = this.fetchMarkers[1];
    this.markersCount = this.markersInfo.length;
    this.innerIndexs = this.fetchMarkers[2];

    let innerMarkers = this.getInnerArray(this.markers)
    this.innerMarkers = innerMarkers

    this.targettimelapse = 0;
    this.meshtimelapse = 0;

    let popMixer = this.popAnimationMixer()
    this.popMixer = popMixer

    let opacityMixer = this.opacityMixer();
    this.opacityMixer = opacityMixer

    let moveMixes = this.meshMixers()
    this.movementMixers = moveMixes

    this.target = {
      min:0,
      max:0.2
    }
    this.updated = false
    this.loaded = false

    this.centerPoint = this.centerPoint()
  }

  //Meshs
  createMarkerArray=()=>{
    let array = []
    let innerIndexes = []
    let markersArray = []
    let markersInfo = []
    let info = ``
    let i = 0;
    let poNum = 1;
    let marker;
    for (let x = 0; x < this.columns +2; x++) {
      for (let y = 0; y < this.rows +2; y++) {
        if(x>0 && x < this.columns +1 && y>0 && y < this.rows +1){
          innerIndexes.push(i)
          info = `item: ${i} is project ${poNum} x: ${x} + y: ${y}`
          marker = new MarkerBlockProject(x,y,poNum)
          poNum++
        }else{
          info = `item: ${i} is blank x: ${x} + y: ${y}`
          marker = new MarkerBlockBlank(x,y)
        }
        i++
        markersInfo.push(info)
        markersArray.push(marker)
      }
    }
    array.push(markersInfo)
    array.push(markersArray)
    array.push(innerIndexes)

    return array
  }

  getInnerArray=(array)=>{
    let result = []
    this.innerIndexs.forEach(i => result.push(array[i]));
    return result
  }

  group = () =>{
    let group = new THREE.Group();

    this.markers.forEach(element =>{
        let mesh = element.mesh
        group.add(mesh)
    })
    
    group.name = "MarkerMesh"

    return group
  }
  
  //Mixers
  popAnimationMixer =()=>{
    let opacity_KF = new THREE.NumberKeyframeTrack( '.material.opacity', [0,2,3,4], [ 1, 0, 0, 0] );
    let opacity_Clip = new THREE.AnimationClip('Opacity Action',-1,[opacity_KF])
    let opacity_Group = new THREE.AnimationObjectGroup()
    this.innerMarkers.forEach(element => {
      //console.log(element.pop)
      element.pop.group.children.forEach(obj =>{
        opacity_Group.add(obj)
      })
    });
    let opacity_Mixer = new THREE.AnimationMixer(opacity_Group);

    let opacity_Action = opacity_Mixer.clipAction( opacity_Clip );
    opacity_Action.loop = THREE.LoopPingPong
    opacity_Action.play();

    return opacity_Mixer
  }

  centerPoint = () =>{
    let locTopLeft = locationMethod(0,0)
    let locBottomRight = locationMethod(this.columns+1,this.rows+1)
    //console.log(`TOP LEFT - start x:${locTopLeft[0]} y:${locTopLeft[1]} z:${locTopLeft[2]} end x:${locTopLeft[3]} y:${locTopLeft[4]} z:${locTopLeft[5]}`)
    //console.log(`BOTTOM RIGHT - start x:${locBottomRight[0]} y:${locBottomRight[1]} z:${locBottomRight[2]} end x:${locBottomRight[3]} y:${locBottomRight[4]} z:${locBottomRight[5]}`)
    let midLoc = [(locTopLeft[3]+locBottomRight[3])/2,(locTopLeft[4]+locBottomRight[4])/2,(locTopLeft[5]+locBottomRight[5])/2]
    //console.log(`Middle end x:${midLoc[0]} y:${midLoc[1]} z:${midLoc[2]}`)
    return midLoc
  }

  opacityMixer = () =>{
    let opacity00_KF = new THREE.NumberKeyframeTrack( '.material.opacity', [0,2,3,4], [ 0, 1, 1,0 ] );
    let opacity00_Clip = new THREE.AnimationClip('Opacity Action',-1,[opacity00_KF])
    let opacityGroup = new THREE.AnimationObjectGroup()

    this.markers.forEach(element =>{
      let mesh = element.mesh
        mesh.children.forEach(element => {
          opacityGroup.add(element)
        });
    })
    let opacityMixer = new THREE.AnimationMixer(opacityGroup);
    let opacityAction = opacityMixer.clipAction( opacity00_Clip );
    opacityAction.loop = THREE.LoopPingPong
    opacityAction.play();

    return opacityMixer

  }

  meshMixers = () =>{
    let markerMeshMixers = []
    this.markers.forEach(element =>{
      let mesh = element.mesh
      let clip = element.clip
      let markerMixer = new THREE.AnimationMixer(mesh)
      let clipAction = markerMixer.clipAction(clip)
      clipAction.loop = THREE.LoopPingPong
      clipAction.play()
      markerMeshMixers.push(markerMixer)
    })

    return markerMeshMixers
  }

  //Update procedures After Everything Loaded
  update(){
    if(this.loaded && !this.updated){
      console.log(`check markers update`)
      this.innerMarkers.forEach(element => {
        element.update()
      });
      this.updated = true
      if(this.updated){
        console.log(`updated all markers`)
      }
    }else if(!this.loaded){
      let list = []
      this.innerMarkers.forEach(element => {
        //console.log(element.loaded)
        list.push(element.loaded)
      });
      this.loaded = list.every(v => v === true)
      if(this.loaded){
        console.log(`loaded all markers`)
      }
    }
  }

  //Animation
  animateTarget(i,delta){
    this.targettimelapse = this.targettimelapse+delta
    let location = this.targettimelapse - Math.floor(this.targettimelapse/2)*2
    let wave
    if(location<1){
      wave = location
    }else{
      wave = Math.abs(location-2)
    }
    wave = this.target.min + wave*this.target.max
    //console.log(`time lapsed:${this.targettimelapse}`)
    //console.log(`delta: ${delta}`)
    this.innerMarkers[i].animateTargets(delta)
  }

  animateTargets(sVal,eVal){
    this.innerMarkers.forEach(element => {
      element.animateTargetsRange(sVal,eVal)
    });
  }

  animateMeshs(delta){
    //this.animateTargetsRange(0.5)
    this.markers.forEach(element => {
      element.animateMesh(delta)
    });
  }


}

export default MarkerBlocks